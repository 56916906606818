// Import required libraries using package manager
import React from "react";
// Reach Router
import { Router, Link } from "@reach/router";
// Modules
import MainNavbar from "../main/MainNavbar";
import MainFooter from "../main/MainFooter";
// Blueprint
import { Button, IPanelProps, PanelStack } from "@blueprintjs/core";
// CSS
import "./report.scss";
// images
import image1 from "../../../public/img/website_framework-1.png";

class Report extends React.Component {
  render() {
    let {
      loading,
      bp: { theme }
    } = this.props;
    console.log(theme);
    return (
      <div className="report-grid">
        <div className="report-nav-general report-nav-main">
          <MainNavbar {...this.props} />
        </div>
        <div
          className={`report-title-general report-title-bumper-left bp-app-background ${theme}`}
        />
        <div
          className={`report-title-general report-title-main bp-app-background ${theme}`}
        >
          <h2 className={`bp3-heading bp3-${theme}`}>Project Proposal</h2>
        </div>
        <div
          className={`report-title-general report-title-bumper-right bp-app-background ${theme}`}
        />
        <div
          className={`report-page-general report-page-bumper-left bp-content ${theme}`}
        />
        <div className={`bp3-${theme}`}>
          <div
            className={`report-page-general report-page-main bp-content ${theme}`}
          >
            <div id="title">
              <h3 className="bp3-heading">
                Title (placeholder): Exploration of use-cases for the R DOM
                package
              </h3>
              <p>
                The following is a project proposal for{" "}
                <a href="https://www.cs.auckland.ac.nz/courses/compsci791/">
                  {" "}
                  COMPSCI 791
                </a>{" "}
                for the 2020 academic year. This proposal identifies the primary
                technology being researched, the DOM package for R, and presents
                a web-application experimental platform to host and share the
                results of the research effort.
              </p>
              <p>
                Preliminary work to-date has focused on developing the
                experimental website platform that will allow a thorough
                exploration and demonstration of the library within various
                use-cases. The webiste is comprised of industry leading
                technologies that were composed to offer a flexibile and
                efficient environment to work within.
              </p>
              <p>
                The git repository for the experimental web platform is{" "}
                <a href="https://github.com/kcullimore/dom_app">here</a>. Note:
                the repository is currently private so please send an email to{" "}
                <a href="mailto: kane@ia.house">kane@ia.house</a> to request
                access.
              </p>
            </div>
            <div id="overview">
              <h3 className="bp3-heading">Overview</h3>
              <p>
                Internet based applications are an increasingly popular way to
                communicate and interact wtih complex data. This might include a
                business application that assist employees unverstand the
                current state of the market. It might also include a news
                website communicating techincal details from a story such census
                data. These usually take a set of data which are then analyzed,
                processed and displayed within an interactive graph to uncover
                valuable insights.
              </p>
              <p>
                The use-cases for such applications are expanding into areas
                that require more sophisticated analytical tools. Industry
                standard tools and techniques for these types of analyses are
                slowly being introduced to these <em>web apps</em>.
                Unfortunately the transition into the web technology stack can
                be difficult and often there are no <i>off-the-shelf</i>{" "}
                solutions.
              </p>
            </div>

            <div id="RPackageIntro">
              <h5 className="bp3-heading">R Packages for the Web </h5>
              <p>
                The{" "}
                <a href="https://en.wikipedia.org/wiki/R_(programming_language)">
                  R programming language
                </a>{" "}
                is a favorite tool for research that rely on complex statistics.
                The language has many reliable libraries and methods to perform
                sophisicated statistical analyses in a relatively short amount
                of code. Such analyses might be possible to perform with
                web-based languages such as Javascript or PHP however the
                process is more likely to require re-inventing much of what is
                already available within R.
              </p>
              <p>
                There are a few active projects which offer ways to incorporate
                R within web applications as shown in the table below. The{" "}
                <strong>httpuv</strong> package is a lower level set of methods
                which handle HTTP and Websocket requests from within R. The
                other three packages incorporate httpuv and then extend upon it.
                The most popular of these is <strong>R Shiny</strong> which
                offers a framework to build dashboard applications from a
                selection of pre-defined components. While R Shiny makes
                development of web applications relatively easy its framework is
                restrictive, especially for large and/or complex use-cases.
              </p>
            </div>
            <div id="rPackageTable" className="report-center-grid">
              <table className="report-table bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-html-table-condensed">
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "50%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>R Package</th>
                    <th>Author</th>
                    <th>Flexibility</th>
                    <th>Development</th>
                    <th>Summary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a href="https://github.com/rstudio/httpuv/">httpuv</a>
                    </td>
                    <td>
                      <a href="https://rstudio.com/">RStudio Team</a>
                    </td>
                    <td>High</td>
                    <td>Hard</td>
                    <td>
                      A low-level package that enables R to communicate with
                      HTTP and Websocket requests. The package provides a means
                      of communication with I/Os and hosting a server from a R
                      session.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="https://github.com/pmur002/DOM">DOM</a>
                    </td>
                    <td>
                      <a href="https://www.stat.auckland.ac.nz/%7Epaul/">
                        Paul Murrell (University of Auckland)
                      </a>
                    </td>
                    <td>High</td>
                    <td>Medium</td>
                    <td>
                      Enables a means to create, destroy and edit HTML DOM
                      elements from within R. The methods enable R to drive a
                      web application by linking the DOM elements with function
                      calls within the R environment.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="https://fiery.data-imaginist.com/">Fiery</a>
                    </td>
                    <td>
                      <a href="https://www.data-imaginist.com/about">
                        Thomas Lin Pedersen (RStudio)
                      </a>
                    </td>{" "}
                    <td>Medium</td>
                    <td>Medium</td>
                    <td>
                      Fiery is designed to work with a R based web server but
                      does not provide nor control the set of web components
                      that can be used with the event handlers.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href="https://shiny.rstudio.com/">R Shiny</a>
                    </td>
                    <td>
                      <a href="https://rstudio.com/">RStudio Team</a>
                    </td>
                    <td>Low</td>
                    <td>Easy</td>
                    <td>
                      R Shiny provides a framework to develop and host a web
                      application from within the R programming environment. The
                      library offers a quick and relatively easy means to create
                      a web application which incorporates the power of R within
                      the back-end.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div id="RDomPackage">
              <h5 className="bp3-heading">The R DOM Package </h5>
              <p>
                The <a href="https://github.com/pmur002/DOM">DOM</a> package for
                R offers an alternative way to connect R with web applications.
                The package is flexible and unopinionated in the way in which it
                enables a work-flow between R and a website. The package also
                incorporates the <strong>httpuv</strong> and communicates with a
                target website via an active websocket that remains open
                throughout the session. The websocket connects is a duplex I/O
                connection that enables data to flow between the R session and
                website in both directions. In its most basic use-case, the
                package hosts a website via a httpuv-based server and then
                enables the creation and modification of html elements via the
                websocket.
              </p>
              <p>
                The <strong>R Shiny</strong> package provides a concise set of
                html components to control from within R. On the othe hand, the{" "}
                <strong>DOM</strong> package provides controls to modify the
                basic website elements. In this way, building a web application
                based upon DOM package offers much more flexibility in what is
                possible. However, this flexibility comes at a cost of having to
                create and style html components from scratch.{" "}
              </p>
            </div>
            <div id="projectIntent">
              <h3 className="bp3-heading">Project Intent</h3>
              <p>
                The overall intention of this project is to explore use-cases
                for the DOM package. This effort will be broken into two phases:
                (1) use-case experiments and (2) extending functionality of the
                DOM package. The first phase will remain open-ended and flexible
                in terms of deciding which use-cases are to be researched with
                more effor than others based on feedback recieved during the
                project. The second phase will most likely result in updating
                the existing DOM package <strong>or</strong> in the creation of
                an additional R package to extend into a specific use-case.
              </p>
              <p>
                A list of potential <strong> use-cases </strong> is provided
                below as an initial starting place:{" "}
              </p>
              <ul>
                <li>
                  Interactive graphics via SVG package (with & without D3.js)
                </li>
                <li>
                  Teaching tool (statistics, mathematics, R programming, etc)
                </li>
                <li>Server side data wrangling/cleaning</li>
                <li>Web crawling (?)</li>
                <li>Others TBD</li>
              </ul>
            </div>
            <div id="expPlatform">
              <h3 className="bp3-heading">Experimental Platform</h3>
              <p>
                The project will be hosted on an active website to demonstrate
                the research effort via interactive examples and help organize
                related information and results. At the time of writing this
                proposal the <em>skeleton</em> of the website has be developed
                with all technologies functioning at a basic level. As the
                project moves forward the website will continue to be updated
                and expanded upon to better host and communicate the work.
              </p>

              <div id="websiteDesign">
                <h5 className="bp3-heading">Website Design</h5>
                <p>
                  The website is designed to accomodate the following functions:
                  (1) communicate research effort, (2) provide interactive
                  demonstration of use-cases and (3) act as an experimental
                  platform for the core devolopment work. The website is
                  organized in the following components:
                </p>

                <ul>
                  <li>
                    <Link to="/">Home Page</Link>: Public landing page to
                    provide a brief overview of the current experiements
                  </li>
                  <li>
                    <Link to="/report">Report</Link>: A collection of project
                    progress reports that will be created on a weekly or
                    bi-weekly cadence.
                  </li>
                  <li>
                    <Link to="/project">Projects</Link>: An active list of all
                    experiements in various states of completion. The design and
                    organization of each project will vary.
                  </li>
                  <li>
                    <Link to="/dataset">Datasets</Link>: Interface to database
                    containing datasets used in the experiments. All CRUD
                    operations will be available to access, manage and review
                    the datasets.
                  </li>
                  <li>
                    <Link to="/code">Code Snippets</Link>: Interface to database
                    containing code snippets that can be loaded within project
                    pages. Such code snippets will allow useful commands and
                    custom functions to be accessible within the website. All
                    CRUD operations will be available to access, manage and
                    review the code snippets.
                  </li>
                  <li>
                    <Link to="/user">User Management</Link>: A page to manage
                    user accounts for access to the website and database(s).
                  </li>
                </ul>
              </div>
              <p>
                Below is a diagram of the front and back ends of the website
                design at its preliminary stage. An explanation of each of the
                technologies is provided in the following paragraph.
              </p>
              <div
                className="report-center-grid"
                style={{ paddingTop: "0.75cm", paddingBottom: "1cm" }}
              >
                <div className="report-card bp3-card bp3-elevation-3">
                  <img src={image1} className="report-img" />
                  <div className="report-img-title">
                    <h5 className="bp3-heading">
                      Experimetal Platform Technologies
                    </h5>
                  </div>
                </div>
              </div>
              <div id="frontendDescrip">
                <h5 className="bp3-heading">Description of the Frontend</h5>
                <p>
                  The web application is designed as a single-page website using
                  the following frameworks and libraries.
                </p>
                <ul>
                  <li>
                    <a href="https://www.w3.org/TR/css-grid-1">CSS Grid</a>: A
                    new feature within the CSS specification that makes website
                    layout easier to position relative to various screen
                    dimensions.
                  </li>
                  <li>
                    <a href="https://blueprintjs.com">Blueprint</a>: A style
                    framework maintained by{" "}
                    <a href="https://www.palantir.com/">Palantir</a> which is
                    designed for data intensive web applications. Especially
                    good for interactive tables and color schemes for data
                    graphics.
                  </li>
                  <li>
                    <a href="https://reactjs.org/">React</a>: A state management
                    javascript library maintained by Facebook. A virtual DOM is
                    used to keep track of web components to render them when
                    called on by user interactions within the webapp.
                  </li>
                  <li>
                    <a href="https://d3js.org/">D3</a>: A charting javascript
                    library that is extremely flexible in plotting customized
                    interactive graphics. Very good at rendering complex charts
                    with animated transitions controlled by user interactions
                    within the webapp. Works within the React framework.
                  </li>
                </ul>
              </div>
              <div id="backendDescrip">
                <h5 className="bp3-heading">Description of the Backend</h5>
                <p>
                  The website has been built within the Amazon Web Services
                  (AWS) environment. The use of AWS{" "}
                  <a href="https://aws.amazon.com/amplify/">Amplify</a> and{" "}
                  <a href="https://aws.amazon.com/appsync/">Appsync</a> greatly
                  speed up development time by offering a Command Line Interface
                  (CLI) and various front-end libraries which provide
                  sophisticated components that can be modified to accomodate
                  the project needs.
                </p>
                <p>
                  The design of the website incorporates a websocket connection
                  between the front-end and a R session running on the back-end.
                  This is achieved by creating a child-process from a node.js
                  server. The website is also connected to a AWS hosted database
                  and uses GraphQL as the API query language. The database will
                  store datasets and code snippets used within the experiments.
                  In addition the website also has a S3 bucket for static file
                  retrieval and can accomodate connections to external
                  databasees via the same GraphQL API interface.
                </p>
                <ul>
                  <li>
                    <a href="https://aws.amazon.com/amplify/">AWS Amplify</a>:
                    ToDO...
                  </li>
                  <li>
                    <a href="https://aws.amazon.com/appsync/">AWS AppSync</a>:
                    ToDO...
                  </li>
                  <li>
                    <a href="https://graphql.org/">GraphQL</a>: ToDO...
                  </li>

                  <li>
                    <a href="https://aws.amazon.com/dynamodb/">DynamoDB</a>:
                    ToDO...
                  </li>
                  <li>
                    <a href="https://aws.amazon.com/lambda/">Lambda</a>: ToDO...
                  </li>
                  <li>
                    <a href="https://aws.amazon.com/s3/">S3</a>: ToDO...
                  </li>
                  <li>
                    <a href="https://nodejs.org">Node</a>: ToDO...
                  </li>
                  <li>
                    <a href="https://nodejs.org/api/child_process.html">
                      Node Child Process
                    </a>
                    : ToDO...
                  </li>
                  <li>
                    <a href="https://developer.mozilla.org/en-US/docs/Web/API/WebSockets_API">
                      Websocket
                    </a>
                    : ToDO...
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`report-page-general report-page-bumper-right bp-content ${theme}`}
        />
        {/*-- Footer --*/}
        <div
          className={`report-footer-general report-footer-bumper-right bp-app-background ${theme}`}
        />
        <div
          className={`report-footer-general report-footer-main bp-app-background ${theme}`}
        >
          <MainFooter {...this.props} />
        </div>
        <div
          className={`report-footer-general report-footer-bumper-left bp-app-background ${theme}`}
        />
      </div>
    );
  }
}

export default Report;
