// Import required libraries using package manager
import React from "react";
// Modules

class DataTransform extends React.Component {
  render() {
    let { loading } = this.props;

    return (
      <div className="data-transform">
        <h3>Data Transform</h3>
      </div>
    );
  }
}

export default DataTransform;
