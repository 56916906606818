// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:7c54e782-81e7-44c5-a80f-1108b58dd1bc",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_eXZqhhzfH",
    "aws_user_pools_web_client_id": "165ki85rjssqlbeibp7t3nm2g7",
    "oauth": {},
    "aws_content_delivery_bucket": "domprojhosting-dev",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d19tsztdll9grd.cloudfront.net"
};


export default awsmobile;
