// Import required libraries using package manager
import React from "react";
import axios from "axios";
// Modules

const SERVER_URL = "http://localhost:3005";
const FILE_PATH = "/data/JSON/";
const FILE_NAME = "test.json";
const URL = `${SERVER_URL}${FILE_PATH}${FILE_NAME}`;

class FromJSON extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadError: "",
      dataset_id: 0,
      dataset_data: []
    };
  }
  componentDidMount() {
    axios.get(`${URL}`).then(response => console.log(response.data));
  }

  render() {
    let { loading } = this.props;

    return (
      <div className="data-transform">
        <h3>From JSON</h3>
      </div>
    );
  }
}

export default FromJSON;
