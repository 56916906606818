// Import required libraries using package manager
import React from "react";
// Reach Router
import { Link } from "@reach/router";
// CSS
import "./main_header.scss";
// Images
import title1 from "../../../public/img/iah_title.svg";
import title2 from "../../../public/img/iah_logo_style_1.svg";

class MainHeader extends React.Component {
  render() {
    let {
      SERVER_URL,
      bp: { theme }
    } = this.props;

    return (
      <header className={`header-grid bp-panel ${theme}`}>
        <Link className="iah-title" to="/">
          <img className="iah-svg" src={title1} alt="information art house" />
        </Link>
        <Link className="iah-logo" to="/">
          <img className="iah-svg" src={title2} alt="information art house" />
        </Link>
      </header>
    );
  }
}

export default MainHeader;
