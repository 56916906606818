// Import components --------------------------------------------------------
// React
import React from "react";
import ReactDOM from "react-dom";
// AWS Amplify
import Amplify from "aws-amplify";
import configuration from "./aws-exports";
const appSyncConfiguration = {
  aws_appsync_graphqlEndpoint:
    "https://adzlwypzajezfnu45fustzzjhm.appsync-api.us-west-2.amazonaws.com/graphql",
  aws_appsync_region: "us-west-2",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-su6jqpz4lzec3bkjlsdg5sptxi"
};
Amplify.configure({ ...configuration, ...appSyncConfiguration });
// Blueprint CSS & SASS variables
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/core/lib/scss/variables";
// Modules
import App from "./js/App";

// Declare App constants and callbacks -------------------------------------
// HTML <div> to load Application
const TARGET_DIV = "AppDiv";

class Application extends React.Component {
  render() {
    return <App />;
  }
}

ReactDOM.render(<Application />, document.getElementById(TARGET_DIV));
