// Import required libraries using package manager
import React from "react";
// Reach Router
import { Router, Link, navigate } from "@reach/router";
// Ace
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-r";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-pastel_on_dark";
// PDF viewer
import { Document, Page } from "react-pdf/dist/entry.parcel";
// Modules
import MainHeader from "./MainHeader";
import MainNavbar from "./MainNavbar";
import MainFooter from "./MainFooter";

// CSS
import "./main_content.scss";

// Images
import image1 from "../../../public/img/tmp_proj_1.pdf";
import image2 from "../../../public/img/tmp_proj_2.pdf";
import image3 from "../../../public/img/tmp_proj_3.pdf";
import image4 from "../../../public/img/tmp_proj_4.pdf";

class MainContent extends React.Component {
  render() {
    const RtmpCode = `
    ## This is a R code snippet
    ## Update data frame with column to identify train (0) and test (1) 
    train_test <- rep(0, nrow(newdf))
    newdf <- cbind(newdf, train_test)
    ## Loop over months to update train_test column with 1000 test (1) rows per month
    set.seed(999) ## seed to hold random sample constant during development
    months <- unique(newdf$month)
    for (month in months) {
      month_row <- which(newdf$month == month)
      random_rows <- sample(month_row, 1000)
      newdf$train_test[random_rows] <- 1
    }
    ## Pull out the train (0) and test (1) data and drop tmp column
    train <- newdf[newdf$train_test == 0, 1:2]
    test <- newdf[newdf$train_test == 1, 1:2]
    `;
    const JStmpCode = `
    // This is a Javascript code snippet
    export function hasModifier (
      modifiers: ts.ModifiersArray,
      ...modifierKinds: ts.SyntaxKind[]
    )
    {
      if (modifiers == null || modifierKinds == null) {
	return false;
      }
      return modifiers.some(m => modifierKinds.some(
	k => m.kind === k));
    }
    `;
    let {
      bp: { theme }
    } = this.props;

    return (
      <div className="main-grid">
        {/*-- Top Bar --*/}
        <div
          className={`top-bar-general top-bar-left bp-app-background ${theme}`}
        />
        <div
          className={`top-bar-general top-bar-main bp-app-background ${theme}`}
        />
        <div
          className={`top-bar-general top-bar-right bp-app-background ${theme}`}
        />
        {/*-- Header --*/}
        <div
          className={`header-general header-bumper-left bp-app-background ${theme}`}
        />
        <div
          className={`header-general header-main bp-app-background ${theme}`}
        >
          <MainHeader {...this.props} />
        </div>
        <div
          className={`header-general header-bumper-right bp-app-background ${theme}`}
        />
        {/*-- Navbar --*/}
        <div className="med-bar-general med-bar-main">
          <MainNavbar {...this.props} />
        </div>
        {/*-- Content --*/}
        <div
          className={`content-general content-bumper-left bp-content ${theme}`}
        />
        <div className={`content-general content-main bp-content ${theme}`}>
          <div
            className={`bp3-card bp3-elevation-3  main-content-card-grid bp-card-background bp3-${theme} ${theme}`}
          >
            <Link to="/project/01">
              <h2 className="bp3-heading main-content-card-title main-center-grid">
                <a>Project 1: Title Here</a>
              </h2>
            </Link>
            <Document
              file={image1}
              className="main-content-card-img main-center-grid"
              renderMode="svg"
              height="100%"
            >
              <Page pageNumber={1} />
            </Document>
            <AceEditor
              name="aceRConsole"
              className="main-content-card-code main-center-grid"
              width="100%"
              readOnly={true}
              wrapEnabled={true}
              mode="javascript"
              value={JStmpCode}
              placeholder="R code resides here"
              theme="pastel_on_dark"
              showGutter={false}
              minLines={20}
              maxLines={20}
              fontSize="16px"
            />
          </div>

          <div
            className={`bp3-card bp3-elevation-3  main-content-card-grid bp-card-background bp3-${theme} ${theme}`}
          >
            <Link to="/project/02">
              <h2 className="bp3-heading main-content-card-title main-center-grid">
                <a>Project 2: Title Here</a>
              </h2>
            </Link>
            <Document
              file={image4}
              className="main-content-card-img main-center-grid"
              renderMode="svg"
              height="100%"
            >
              <Page pageNumber={1} />
            </Document>
            <AceEditor
              name="aceJSConsole"
              className="main-content-card-code main-center-grid"
              width="100%"
              readOnly={true}
              wrapEnabled={true}
              mode="javascript"
              value={JStmpCode}
              placeholder="R code resides here"
              theme="pastel_on_dark"
              showGutter={false}
              minLines={20}
              maxLines={20}
              fontSize="16px"
            />
          </div>

          <div
            className={`bp3-card bp3-elevation-3  main-content-card-grid bp-card-background bp3-${theme} ${theme}`}
          >
            <Link to="/project/03">
              <h2 className="bp3-heading main-content-card-title main-center-grid">
                <a>Project 3: Title Here</a>
              </h2>
            </Link>
            <Document
              file={image3}
              className="main-content-card-img main-center-grid"
              renderMode="svg"
              height="100%"
            >
              <Page pageNumber={1} />
            </Document>
            <AceEditor
              name="aceRConsole"
              className="main-content-card-code main-center-grid"
              width="100%"
              readOnly={true}
              wrapEnabled={true}
              mode="javascript"
              value={JStmpCode}
              placeholder="R code resides here"
              theme="pastel_on_dark"
              showGutter={false}
              minLines={20}
              maxLines={20}
              fontSize="16px"
            />
          </div>
        </div>
        <div
          className={`content-general content-bumper-right bp-content ${theme}`}
        />
        {/*-- Footer --*/}
        <div
          className={`footer-general footer-bumper-right bp-app-background ${theme}`}
        />
        <div
          className={`footer-general footer-main bp-app-background ${theme}`}
        >
          <MainFooter {...this.props} />
        </div>
        <div
          className={`footer-general footer-bumper-left bp-app-background ${theme}`}
        />
      </div>
    );
  }
}

export default MainContent;
