// Import required libraries using package manager
import React from "react";
import axios from "axios";
import convert from "xml-js";

// Modules

const SERVER_URL = "http://localhost:3005";
const FILE_PATH = "/data/XML/";
const FILE_NAME = "test.xml";
const URL = `${SERVER_URL}${FILE_PATH}${FILE_NAME}`;

class FromXML extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadError: "",
      dataset_id: 0,
      dataset_data: []
    };
  }
  componentDidMount() {
    let options = { ignoreComment: true, alwaysChildren: true };
    axios.get(`${URL}`).then(response => {
      console.log(convert.xml2js(response.data, options));
    });
  }

  render() {
    let { loading } = this.props;

    return (
      <div className="data-transform">
        <h3>From XML</h3>
      </div>
    );
  }
}

export default FromXML;
