// Import required libraries using package manager
import React from "react";
// CSS
import "./main_footer.scss";

class MainFooter extends React.Component {
  render() {
    let dateObj = new Date().getFullYear();
    let {
      bp: { theme }
    } = this.props;

    return (
      <footer className="footer-grid">
        <p className={`footer-text ${theme}`}>
          {"\u00A9"} {dateObj} Information Art House
        </p>
      </footer>
    );
  }
}

export default MainFooter;
