// Import required libraries using package manager
import React, { Fragment } from "react";
// Modules
//import ProjectDisplayExp from "../ProjectDisplayExp";
//import DataTest from "../../dataset/transform/DataTest";
// CSS

const TARGET_DIV = "projectDisplayDiv";

class Project02 extends React.Component {
  constructor(props) {
    super(props);

    this.onResize = this.onResize.bind(this);

    this.state = {
      displaySize: {
        width: 200,
        height: 200
      }
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize, false);
    this.onResize();
  }

  onResize() {
    let divWidth = document.getElementById(TARGET_DIV).offsetWidth;
    let divHeight = document.getElementById(TARGET_DIV).offsetHeight;
    this.setState({
      displaySize: {
        width: divWidth,
        height: divHeight
      }
    });
  }

  render() {
    let { loading } = this.props;
    let { displaySize } = this.state;

    return (
      <Fragment>
        <div className="project-title">
          <h1 className="caption-title">Chart-2: ABC XYZ</h1>
          <h3 className="caption-description">Blah blah blah</h3>
        </div>
        <div id="projectDisplayDiv" className="project-display">
          {/*           <ProjectDisplayExp loading={loading} displaySize={displaySize} /> */}
        </div>
        <div id="projectCodeDiv" className="project-code">
          <p className="iah-text-code">
            The width of the SVG is {displaySize.width}.
            <br />
            The height of the SVG is {displaySize.height}.
          </p>
        </div>
        {/*         <DataTest /> */}
      </Fragment>
    );
  }
}

export default Project02;
