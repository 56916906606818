// Import required libraries using package manager
import React, { Fragment } from "react";

class Secret extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "Loading..."
    };
  }
  componentDidMount() {
    //GET message from server using fetch api
    fetch("http://localhost:3005/api/user/secret")
      .then(res => res.text())
      .then(res => this.setState({ message: res }));
  }
  render() {
    return (
      <Fragment>
        <h1>Secret</h1>
        <p>{this.state.message}</p>
      </Fragment>
    );
  }
}

export default Secret;
