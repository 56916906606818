// Import required libraries using package manager
import React, { Fragment } from "react";
// Modules
//import ProjectDisplay from "../ProjectDisplay";
import FromCSV from "../../dataset/transform/FromCSV";
import FromJSON from "../../dataset/transform/FromJSON";
import FromTXT from "../../dataset/transform/FromTXT";
import FromXML from "../../dataset/transform/FromXML";
import DataTransform from "../../dataset/transform/DataTransform";

class Project03 extends React.Component {
  render() {
    let { loading } = this.props;

    return (
      <Fragment>
        <div className="project-title">
          <h1 className="caption-title">
            Project-3: Data Transformation Examples
          </h1>
          <h3 className="caption-description">
            Data will be sourced via different formatted files and then
            transformed to display within the browser.
          </h3>
        </div>
        <div id="projectDisplayDiv" className="project-display">
          {/*           <ProjectDisplay loading={loading} /> */}
        </div>
        <div id="projectCodeDiv" className="project-code">
          <p className="iah-text-code">
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
          </p>
          <FromCSV />
          <FromJSON />
          <FromTXT />
          <FromXML />
          <DataTransform />
        </div>
      </Fragment>
    );
  }
}

export default Project03;
