// Import required libraries using package manager
import React from "react";
// Modules
import CodeEditor from "../../utils/CodeEditor";
// PDF viewer
import { Document, Page } from "react-pdf/dist/entry.parcel";
// CSS
import "./project_04.scss";
// Images
import image1 from "../../../../public/img/tmp_proj_1.pdf";

class Project04 extends React.Component {
  render() {
    const { loading } = this.props;

    return (
      <div className="project-04-grid">
        <div className="project-04-title">
          <h4 className="bp3-heading">Initial Testing: R plot via websocket</h4>
        </div>
        <div className="project-04-display">
          <Document
            file={image1}
            className="card-img"
            renderMode="svg"
            height="100%"
          >
            <Page pageNumber={1} />
          </Document>
          {/* <div>
              <svg>
              <rect
              x="-500"
              y="-500"
              width="1000px"
              height="1000px"
              fill="green"
              />
              </svg>
              </div> */}
        </div>
        <CodeEditor
          className="project-04-code"
          editorName="R-test"
          editorMode="r"
          editorTheme="solarized_dark"
          lineNumber={true}
          codePlaceholder="## Type some R here"
          fontSize="20px"
        />
        <div className="project-04-ui" />
        {/*         <div className="project-04-console" /> */}
      </div>
    );
  }
}

export default Project04;
