// Import required libraries using package manager
import React, { Fragment } from "react";

class Blank extends React.Component {
  render() {
    return (
      <Fragment>
        <h1>Blank</h1>
      </Fragment>
    );
  }
}

export default Blank;
