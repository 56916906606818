// Import required libraries using package manager
import React from "react";
import { Button, H1 } from "@blueprintjs/core";

class RForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      response: 0,
      endpoint: "localhost:3005",
      rCode: "x<-1:10; print(x)",
      socket: {}
    };
  }

  componentDidMount() {
    const { endpoint } = this.state;

    const socket = new WebSocket(`ws://${endpoint}`);
    this.setState({ socket });

    socket.onopen = function(event) {
      // socket.send("Browser connected!");
    };

    socket.onclose = function(event) {
      console.log("Leaving websocket");
      socket.send("Browser leaving websocket.");
    };

    socket.onerror = function(event) {
      console.error("WebSocket error observed:", event);
    };

    socket.onmessage = function onmessage(event) {
      console.log(`Browser socket just received: ${event.data}`);
    };

    // socket.close();
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ rCode: value });
    console.log(this.state.rCode);
  };

  handleSubmit = e => {
    this.state.socket.send(
      `{"name": "forR", "command": "${this.state.rCode}" }`
    );
    // alert("An essay was submitted: " + this.state.rCode);
    event.preventDefault();
  };

  resetRCode = e => {
    this.setState({ rCode: "getwd()" });
  };

  render() {
    return (
      <div className="r-Form">
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="RCode">
            R Input
            <textarea
              id="rCommand"
              value={this.state.rCode}
              placeholder="r-code"
              onChange={this.handleChange}
            />
          </label>
          <Button onClick={this.resetRCode}>Reset</Button>
          <Button onClick={this.handleSubmit}>Submit</Button>
        </form>
        <div id="chat">
          <H1>Yo</H1>
          <ul id="message" />
          <form id="form" action="">
            <input type="text" id="input" />
            <button>Send</button>
          </form>
        </div>
      </div>
    );
  }
}

export default RForm;
