// Import required libraries using package manager
import React, { Fragment } from "react";
// Modules
// import socketIoClient from "socket.io-client";
/* import ProjectDisplay from "../ProjectDisplay"; */
import RForm from "./RForm";

class Project01 extends React.Component {
  /* constructor(props) {
   *   super(props);
   *   this.state = {
   *     response: 0,
   *     endpoint: "localhost:3005"
   *   };
   * } */

  /* componentDidMount() {
   *   const { endpoint } = this.state;
   *   //Very simply connect to the socket
   *   const socket = socketIoClient(endpoint);
   *   //Listen for data on the "outgoing data" namespace and supply a callback for what to do when we get one. In this case, we set a state variable
   *   socket.on("outgoing data", data => this.setState({ response: data.num }));
   * } */

  render() {
    let { loading } = this.props;
    /*     let { response } = this.state; */

    return (
      <div className="project-grid">
        <div className="project-title">
          <h1 className="caption-title">Project-1: ABC XYZ</h1>
          <h3 className="caption-description">Blah blah blah</h3>
        </div>
        <div id="projectDisplayDiv" className="project-display">
          {/*           <ProjectDisplay loading={loading} /> */}
        </div>
        <div id="projectCodeDiv" className="project-code">
          {/*           <p className="iah-text-code">{response}</p> */}
          <p className="iah-text-code">
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
            CodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCodeCode
          </p>
          <RForm />
        </div>
      </div>
    );
  }
}

export default Project01;
