// Import required libraries using package manager
import React from "react";
// Reach Router
import { Router, Link } from "@reach/router";
// Modules
import MainNavbar from "../main/MainNavbar";

class DatasetContent extends React.Component {
  render() {
    let {
      loading,
      bp: { theme }
    } = this.props;

    return (
      <div className="report-grid">
        <div className="report-nav-general report-nav-main">
          <MainNavbar {...this.props} />
        </div>
        <div className="report-title-general report-title-bumper-left" />
        <div className="report-title-general report-title-main">
          <h3>Here lies some data...</h3>
        </div>
        <div className="report-title-general report-title-bumper-right" />
        <div className="report-controls-general report-controls-bumper-left" />
        <div className="report-controls-general report-controls-main" />
        <div className="report-form-general report-form-bumper-left" />
        <div className="report-form-general report-form-main" />
        <div className="report-form-general report-form-bumper-right" />

        <div className="report-controls-general report-controls-bumper-right" />
      </div>
    );
  }
}

export default DatasetContent;
