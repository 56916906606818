// Import required libraries using package manager
import React from "react";
// Reach Router
import { Router } from "@reach/router";
// Modules
import MainNavbar from "../main/MainNavbar";
import Project01 from "./01/Project01";
import Project02 from "./02/Project02";
import Project03 from "./03/Project03";
import Project04 from "./04/Project04";
// CSS
import "./project_portfolio.scss";

class ProjectPortfolio extends React.Component {
  render() {
    let {
      loading,
      bp: { theme }
    } = this.props;

    return (
      <div className="portfolio-grid">
        <div className="portfolio-nav-general portfolio-nav-main">
          <MainNavbar {...this.props} />
        </div>
        <div className="portfolio-project-general portfolio-project-bumper-left" />
        <div className="portfolio-project-general portfolio-project-main">
          <Router>
            <Project01 path="1" {...this.props} />
            <Project02 path="2" {...this.props} />
            <Project03 path="3" {...this.props} />
            <Project04 path="4" default {...this.props} />
          </Router>
        </div>
        <div className="portfolio-project-general portfolio-project-bumper-right" />
      </div>
    );
  }
}

export default ProjectPortfolio;
