import React, { Fragment } from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-r";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-pastel_on_dark";
import "ace-builds/src-noconflict/keybinding-emacs";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/r";

// Blueprint components
import {
  Button,
  ButtonGroup,
  FileInput,
  FormGroup,
  InputGroup
} from "@blueprintjs/core";

class CodeEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codeValue: "",
      fileName: "Select file to load...",
      fileSelected: false,
      fileSaveName: "",
      fileSaved: false
    };
    this.onEditorChange = this.onEditorChange.bind(this);
    this.onFileNameChange = this.onFileNameChange.bind(this);
    this.onfileSaveNameChange = this.onfileSaveNameChange.bind(this);
  }

  onEditorChange = newValue => {
    this.setState({ codeValue: newValue });
  };

  onFileNameChange = e => {
    this.setState({ fileName: e.target.value, fileSelected: true });
  };

  onfileSaveNameChange = e => {
    this.setState({ fileSaveName: e.target.value, fileSaved: true });
  };

  render() {
    const tmpCode = `
## Update data frame with column to identify train (0) and test (1) 
train_test <- rep(0, nrow(newdf))
newdf <- cbind(newdf, train_test)
## Loop over months to update train_test column with 1000 test (1) rows per month
set.seed(999) ## seed to hold random sample constant during development
months <- unique(newdf$month)
for (month in months) {
  month_row <- which(newdf$month == month)
  random_rows <- sample(month_row, 1000)
  newdf$train_test[random_rows] <- 1
}
## Pull out the train (0) and test (1) data and drop tmp column
train <- newdf[newdf$train_test == 0, 1:2]
test <- newdf[newdf$train_test == 1, 1:2]
    `;

    let {
      className,
      editorName,
      editorMode,
      editorTheme,
      fontSize,
      lineNumber,
      codePlaceholder
    } = this.props;
    let {
      codeValue,
      fileName,
      fileSelected,
      fileSaveName,
      fileSaved
    } = this.state;

    return (
      <Fragment>
        <FormGroup
          label="Upload a file into code editor"
          labelFor="text-input"
          labelInfo="(optional)"
          intent="Warning"
        >
          <ButtonGroup>
            <FileInput
              text={fileName}
              buttonText="Browse"
              onInputChange={this.onFileNameChange}
              hasSelection={fileSelected}
            />
            <Button
              text="Upload"
              type="submit"
              icon="upload"
              disabled={!fileSelected}
              intent={!fileSelected ? "None" : "Primary"}
            />
            <Button
              text="Delete"
              type="submit"
              icon="trash"
              disabled={!fileSelected}
              intent={!fileSelected ? "None" : "Primary"}
            />
          </ButtonGroup>
        </FormGroup>
        <FormGroup
          label="Save code to file"
          labelFor="text-input"
          labelInfo="(optional)"
          intent="Warning"
        >
          <ButtonGroup>
            <InputGroup
              type="text"
              placeholder="Enter file name..."
              value={fileSaveName}
              onChange={this.onfileSaveNameChange}
              intent={!fileSaved ? "None" : "Primary"}
            />
            <Button
              text="Save"
              type="submit"
              icon="archive"
              disabled={!fileSaved}
              intent={!fileSaved ? "None" : "Primary"}
            />
          </ButtonGroup>
        </FormGroup>
        <AceEditor
          className={className}
          width="100%"
          name={editorName}
          mode={editorMode}
          theme={editorTheme}
          value={codeValue}
          onChange={this.onEditorChange}
          placeholder={codePlaceholder}
          highlightActiveLine={true}
          showGutter={true}
          enableBasicAutocompletion={true}
          enableLiveAutocompletion={true}
          enableSnippets={true}
          keyboardHandler="emacs"
          wrapEnabled={true}
          fontSize={fontSize}
          minLines={20}
          maxLines={20}
          setOptions={{
            tabSize: 2,
            cursorStyle: "Wide",
            showLineNumbers: lineNumber
          }}
        />
        <AceEditor
          name="aceRConsole"
          className="project-04-console"
          width="100%"
          value={tmpCode}
          readOnly={true}
          wrapEnabled={true}
          mode={editorMode}
          placeholder="## Console output..."
          theme="pastel_on_dark"
          showGutter={false}
          minLines={20}
          maxLines={20}
          fontSize="20px"
        />
      </Fragment>
    );
  }
}

export default CodeEditor;
