// Import components --------------------------------------------------------
// React
import React from "react";
// AWS Amplify
import { API, graphqlOperation } from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
//import { GetCodeFile, ListCodeFiles } from "./graphql";
// Reach Router
import { Router } from "@reach/router";

// Modules

import MainContent from "./main/MainContent";
import UserContent from "./user/UserContent";
import UserLogin from "./crud/user/UserLogin";
import Report from "./report/Report";
import ProjectPortfolio from "./project/ProjectPortfolio";
import DatasetContent from "./dataset/DatasetContent";
import CodeContent from "./code/CodeContent";

// CSS
import "./app.scss";
import "../css/iah.scss";

// Declare App constants and callbacks -------------------------------------
const GetCodeFile = `query GetCodeFile {
   getCodeFile(id:"fbf72b9a-042d-453b-8936-6cccddc047fb")
   {    id
     title
     language
     code
   }
 }`;

let ListCodeFiles = `query ListCodeFiles {
      listCodeFiles{
        items
        {
          id
          title
          language
          code
        }
      }
   }`;
const TARGET_DIV = "AppDiv";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.onResize = this.onResize.bind(this);
    this.state = {
      loading: true,
      loadError: "",
      bp: {
        theme: "dark",
        themeClass: "bp3-dark"
      },
      SERVER_URL: "http://localhost:3005",
      screenSize: {
        width: 1000,
        height: 500
      },
      screenMargin: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10
      }
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize, false);
    this.onResize();
    this.setState({ loading: false });
    API.graphql(graphqlOperation(ListCodeFiles))
      .then(res => console.log({ res }))
      .catch(console.error);
  }

  onResize() {
    let divWidth = document.getElementById(TARGET_DIV).offsetWidth;
    let divHeight = document.getElementById(TARGET_DIV).offsetHeight;
    this.setState({
      screenSize: {
        width: divWidth,
        height: divHeight
      },
      screenMargin: {
        top: divHeight * 0.05,
        bottom: divHeight * 0.1,
        left: divWidth * 0.05,
        right: divWidth * 0.025
      }
    });
  }
  // Blueprint theme toggle
  toggle_theme = () => {
    if (this.state.bp.theme == "dark") {
      this.setState({
        bp: {
          theme: "light",
          themeClass: "bp3-light"
        }
      });
    } else {
      this.setState({
        bp: {
          theme: "dark",
          themeClass: "bp3-dark"
        }
      });
    }
  };

  render() {
    return (
      /*       <React.StrictMode> */
      <Router>
        <MainContent
          exact
          path="/"
          {...this.state}
          toggleTheme={this.toggle_theme}
        />
        {/* <UserLogin
            path="/login"
            {...this.state}
            toggleTheme={this.toggle_theme}
            /> */}
        <UserContent
          path="/user/*"
          {...this.state}
          toggleTheme={this.toggle_theme}
        />
        <Report
          path="/report/*"
          {...this.state}
          toggleTheme={this.toggle_theme}
        />
        <ProjectPortfolio
          exact
          path="/project/*"
          {...this.state}
          toggleTheme={this.toggle_theme}
        />
        <DatasetContent
          path="/dataset/*"
          {...this.state}
          toggleTheme={this.toggle_theme}
        />
        <CodeContent
          path="/code/*"
          {...this.state}
          toggleTheme={this.toggle_theme}
        />
      </Router>
    );
  }
}

export default withAuthenticator(App);
