// Import required libraries using package manager
import React from "react";
// Reach Router
import { Router, Link } from "@reach/router";
// Modules
import MainNavbar from "../main/MainNavbar";
import Blank from "../crud/user/Blank";
import User from "../crud/user/User";
import Secret from "../crud/user/Secret";
import UserLogin from "../crud/user/UserLogin";
import UserCreate from "../crud/user/UserCreate";

// CSS
import "./user_content.scss";

class UserContent extends React.Component {
  render() {
    let {
      loading,
      bp: { theme }
    } = this.props;

    return (
      <div className="user-grid">
        <div className="user-nav-general user-nav-main">
          <MainNavbar {...this.props} />
        </div>
        <div className="user-title-general user-title-bumper-left" />
        <div className="user-title-general user-title-main">
          <h3>Here lies a User...</h3>
        </div>
        <div className="user-title-general user-title-bumper-right" />
        <div className="user-controls-general user-controls-bumper-left" />
        <div className="user-controls-general user-controls-main">
          <ul>
            <li>
              <Link to="/user">Home</Link>
            </li>
            <li>
              <Link to="/user/create">User Create</Link>
            </li>
            <li>
              <Link to="/user/login">User Login</Link>
            </li>
            <li>
              <Link to="/user/account">User Account</Link>
            </li>
            <li>
              <Link to="/user/secret">Secret</Link>
            </li>
          </ul>
        </div>
        <div className="user-form-general user-form-bumper-left" />
        <Router className="user-form-general user-form-main">
          <Blank path="/" />
          <UserCreate path="/create" />
          <UserLogin path="/login" history={[]} />
          <User path="/account" />
          <Secret path="/secret" />
        </Router>
        <div className="user-form-general user-form-bumper-right" />
        <div className="user-controls-general user-controls-bumper-right" />
      </div>
    );
  }
}

export default UserContent;
