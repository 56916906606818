// Import required libraries using package manager
import React, { Fragment } from "react";
// Reach Router
import { Link, navigate } from "@reach/router";
// Blueprint components
import {
  Alignment,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Popover,
  Position,
  Text
} from "@blueprintjs/core";

class MainNavbar extends React.Component {
  render() {
    let {
      bp: { themeClass },
      screenSize: { width }
    } = this.props;
    let buttonLayout = width < 400 ? "bp3-vertical" : "";

    return (
      <Navbar className={themeClass}>
        <NavbarGroup align={Alignment.LEFT}>
          <ButtonGroup minimal={false}>
            <Button
              icon="cog"
              onClick={() => {
                this.props.toggleTheme();
              }}
            />
            <Button icon="home" onClick={() => navigate("/")}>
              <Text className="text-media-hide">The DOM Project</Text>
            </Button>
          </ButtonGroup>
        </NavbarGroup>
        <NavbarGroup align={Alignment.RIGHT}>
          <Navbar.Divider className="div-media-hide" />
          <ButtonGroup minimal={false}>
            <Button icon="paperclip" onClick={() => navigate("/report")}>
              <Text className="text-media-hide">Report</Text>
            </Button>
            <Popover
              position={Position.BOTTOM}
              content={
                <Menu>
                  <MenuItem
                    icon="document"
                    text="Project - 1"
                    onClick={() => navigate("/project/1")}
                  />

                  <MenuItem
                    icon="document"
                    text="Project - 2"
                    onClick={() => navigate("/project/2")}
                  />
                  <MenuItem
                    icon="document"
                    text="Project - 3"
                    onClick={() => navigate("/project/3")}
                  />
                  <MenuItem
                    icon="document"
                    text="Project - 4"
                    onClick={() => navigate("/project/4")}
                  />
                </Menu>
              }
            >
              <Button icon="projects">
                <Text className="text-media-hide">Projects</Text>
              </Button>
            </Popover>
            <Button icon="database" onClick={() => navigate("/dataset")}>
              <Text className="text-media-hide">Datasets</Text>
            </Button>
            <Button icon="code" onClick={() => navigate("/code")}>
              <Text className="text-media-hide">Code</Text>
            </Button>
            <NavbarDivider className="div-media-hide" />
            <Button icon="user" onClick={() => navigate("/user")}>
              <Text className="text-media-hide">User</Text>
            </Button>
          </ButtonGroup>
        </NavbarGroup>
      </Navbar>
    );
  }
}

export default MainNavbar;
